<template>
  <v-dialog
    v-model="dialog"
    max-width="400px"
    persistent
  >
    <v-card>
      <v-card-title>
        <span class="headline">パスワード 変更</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <div class="subtitle-1 font-weight-bold mb-1">
            パスワード
          </div>
          <v-text-field
            v-model="form.password"
            outlined
            dense
            type="password"
            placeholder="********"
            :rules="rules.password"
          ></v-text-field>
          <div class="subtitle-1 font-weight-bold mb-1">
            パスワード（確認用）
          </div>
          <v-text-field
            v-model="confirmPassword"
            outlined
            dense
            type="password"
            placeholder="********"
            :rules="rules.confirmPassword"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog=false">キャンセル</v-btn>
        <v-btn color="error" @click="send" :loading="loading">変更</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios"

export default {
  props: {
    userid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      valid: true,
      loading: false,
      form: {
        password: '',
      },
      confirmPassword: '',
      rules: {
        password: [
          v => !!v || '入力してください。',
        ],
        confirmPassword: [
          v => !!v || '入力してください。',
          v => v === this.form.password || 'パスワードが一致しません。'
        ],
      },
    }
  },
  methods: {
    open() {
      this.dialog = true
      this.input = this.value
    },
    send() {
      if (this.$refs.form.validate()) {
        this.save()
      }
    },
    async save() {
      this.loading = true
      let form = {}
      if (this.appmetadata) {
        let app_metadata = {} 
        app_metadata[this.keyname] = this.input
        form.app_metadata = app_metadata
      } else {
        form[this.key] = this.input
      }
      const accessToken = await this.$auth.getTokenSilently()
      await axios.patch(`/api/users/${this.userid}`, this.form, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      .then(() => {
        this.dialog = false
        this.$emit('success')
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false
      })
    },
  }
}
</script>