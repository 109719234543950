<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    persistent
  >
    <v-card>
      <v-card-title>
        <span class="headline">ロール追加</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row v-if="fetchLoading" justify="center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
        <v-form
          class="role-form"
          ref="form"
          v-model="valid"
          lazy-validation
          v-else
        >
          <v-select
            v-model="input"
            :items="notAssignedRoles"
            label="ロール選択"
            multiple
            chips
            deletable-chips
            item-text="name"
            item-value="id"
            :rules="rules.roles"
          ></v-select>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog=false">キャンセル</v-btn>
        <v-btn color="primary" @click="send" :loading="loading">保存</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios"

export default {
  props: {
    userid: {
      type: String,
      required: true,
    },
    assignedRoles: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      dialog: false,
      input: [],
      valid: true,
      loading: false,
      fetchLoading: false,
      roles: [],
      rules: {
        roles: [
          v => !!v || '選択してください。',
        ],
      },
    }
  },
  computed: {
    notAssignedRoles: function() {
      return this.roles.filter(value => !this.assignedRoles.map(obj => obj.id).includes(value.id));
    }
  },
  created() {
    this.fetchRoles()
  },
  methods: {
    open() {
      this.dialog = true
      this.input = []
    },
    send() {
      if (this.$refs.form.validate()) {
        this.save()
      }
    },
    async save() {
      this.loading = true
      let form = { roles: this.input }
      const accessToken = await this.$auth.getTokenSilently()
      await axios.post(`/api/users/${this.userid}/roles`, form, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      .then((response) => {
        this.dialog = false
        this.$emit('success', response.data)
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false
      })
    },
    async fetchRoles() {
      this.fetchLoading = true
      const accessToken = await this.$auth.getTokenSilently()
      await axios
        .get(`/api/roles`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
        })
        .then(response => {
          this.roles = response.data
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.fetchLoading = false;
        });
    },
  }
}
</script>

<style scoped>
.role-form {
  width: 480px;
}
</style>