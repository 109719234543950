<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="roles"
      :loading="loading"
      hide-default-footer
    >
      <template v-slot:top>
        <div class="d-flex justify-end mt-4">
          <v-btn color="primary" @click="openAssign()">
            <v-icon left>
              mdi-plus
            </v-icon>
            ロールをアサイン
          </v-btn>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <assign-role-dialog
      ref="assign"
      :userid="user.user_id"
      :assignedRoles="roles"
      @success="fetch"
    ></assign-role-dialog>

    <delete-role-of-user-dialog
      ref="delete"
      :userid="user.user_id"
      :username="user.name"
      :role="selectedItem"
      @success="fetch"
    ></delete-role-of-user-dialog>
  </div>
</template>

<script>
import axios from "axios"
import AssignRoleDialog from '@/components/AssignRoleDialog'
import DeleteRoleOfUserDialog from '@/components/DeleteRoleOfUserDialog'

export default {
  components: {
    AssignRoleDialog,
    DeleteRoleOfUserDialog
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      headers: [
        { text: 'ロール名', value: 'name'},
        { text: '', value: 'actions'},
      ],
      roles: [],
      selectedItem: {},
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      this.loading = true
      const accessToken = await this.$auth.getTokenSilently()
      await axios
        .get(`/api/users/${this.user.user_id}/roles`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
        })
        .then(response => {
          this.roles = response.data
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openAssign() {
      this.$refs.assign.open()
    },
    deleteItem(item) {
      this.selectedItem = item
      this.$refs.delete.open()
    }
  }
  
}
</script>