<template>
  <div>
    <v-list>
      <v-list-item v-for="item in items" :key="item.text">
        <v-list-item-title>{{ item.text }}</v-list-item-title>

        <v-list-item-subtitle class="d-flex align-center text-left">
          <div v-if="item.date">
            {{ user[item.value] | formatdate }}
          </div>
          <div v-else>
            {{ user[item.value] }}
          </div>
          <v-btn v-if="item.edit" icon x-small @click="item.edit" class="ml-2">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-list-item-subtitle>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <div v-if="user.app_metadata">
      <v-list-item>
        <v-list-item-content>
          <div class="headline">JC3 管理情報</div>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-for="item in metadata" :key="item.value">
        <v-list-item-title>{{ item.text }}</v-list-item-title>

        <v-list-item-subtitle class="d-flex align-center text-left">
          <div v-if="item.value === 'account_level'">
            {{ user.app_metadata[item.value] | accountLevel }}
          </div>
          <div v-else-if="item.value === 'account_type'">
            {{ user.app_metadata[item.value] | accountType }}
          </div>
          <div v-else-if="item.value === 'applicant_verified'">
            {{ user.app_metadata[item.value] ? "確認済み" : "未確認" }}
          </div>
          <div v-else>
            {{ user.app_metadata[item.value] }}
          </div>
          <v-btn v-if="item.edit" icon x-small @click="item.edit" class="ml-2">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-list-item-subtitle>
      </v-list-item>
    </div>

    <v-divider></v-divider>

    <v-list-item two-line>
      <v-list-item-title>パスワード変更</v-list-item-title>

      <v-list-item-subtitle class="text-left">
        <v-btn color="error" @click="openChangePassword"> 変更する </v-btn>
      </v-list-item-subtitle>
    </v-list-item>

    <v-list-item two-line>
      <v-list-item-title>ユーザ削除</v-list-item-title>

      <v-list-item-subtitle class="text-left">
        <v-btn color="error" @click="openDelete"> 削除する </v-btn>
      </v-list-item-subtitle>
    </v-list-item>

    <!-- Edit -->
    <edit-dialog
      ref="edit"
      v-model="editParams.value"
      :type="editParams.type"
      :userid="id"
      :title="editParams.title"
      :keyname="editParams.keyname"
      :placeholder="editParams.placeholder"
      :appmetadata="editParams.appmetadata"
      :rules="editParams.rules"
      :items="editParams.items"
      @success="updateUser"
    ></edit-dialog>

    <!-- Change Password -->
    <change-password-dialog
      ref="changePassword"
      :userid="id"
    ></change-password-dialog>

    <!-- Delete User -->
    <delete-user-dialog
      v-if="user.name"
      ref="deleteUser"
      :userid="id"
      :name="user.name"
      @success="$router.push({ name: 'Users' })"
    ></delete-user-dialog>
  </div>
</template>

<script>
import moment from "moment";

import { ACCOUNT_LEVEL_LIST, ACCOUNT_TYPE_LIST } from "@/mixin/metadata.js";

import EditDialog from "@/components/EditDialog";
import ChangePasswordDialog from "@/components/ChangePasswordDialog";
import DeleteUserDialog from "@/components/DeleteUserDialog";

export default {
  components: {
    EditDialog,
    ChangePasswordDialog,
    DeleteUserDialog,
  },
  props: ["id", "user"],
  data() {
    return {
      items: [
        { text: "Auth0 ID", value: "user_id" },
        { text: "表示名", value: "name", edit: this.openEditName },
        { text: "メールアドレス", value: "email", edit: this.openEditEmail },
        { text: "メール検証", value: "email_verified" },
        { text: "ニックネーム", value: "nickname" },
        { text: "接続元IP", value: "last_ip" },
        { text: "最終ログイン日時", value: "last_login", date: true },
        {
          text: "パスワード変更日時",
          value: "last_password_reset",
          date: true,
        },
        { text: "ログイン回数", value: "logins_count" },
        { text: "登録日時", value: "created_at", date: true },
        { text: "更新日時", value: "updated_at", date: true },
      ],
      app_metadata: [
        {
          text: "会員種別",
          value: "account_level",
          edit: this.openEditAccountLevel,
          isCommon: true,
        },
        {
          text: "所属種別",
          value: "account_type",
          edit: this.openEditAccountType,
          isCommon: true,
        },
        {
          text: "氏名（漢字）",
          value: "fullname",
          isCommon: true,
          edit: this.openEditFullname,
        },
        {
          text: "フリガナ",
          value: "furigana",
          isCommon: true,
          edit: this.openEditFurigana,
        },
        {
          text: "アルファベット表示",
          value: "alphabet_name",
          isCommon: true,
          edit: this.openEditAlphabetName,
        },
        // 正・特定会員のみ
        {
          text: "情報管理規定等適用者確認",
          value: "applicant_verified",
          isRegular: true,
          edit: this.openEditApplicantVerified,
        },
        // 警察のみ
        {
          text: "所属",
          value: "belongs",
          isPolice: true,
          edit: this.openEditBelongs,
        },
        // 所属用のメールアドレス登録時のみ
        {
          text: "連絡先電話番号",
          value: "telephone_number",
          isBelongs: true,
          edit: this.openEditTelephoneNumber,
        },
        {
          text: "上司氏名",
          value: "boss_name",
          isBelongs: true,
          edit: this.openEditBossName,
        },
        {
          text: "上司連絡先",
          value: "boss_email",
          isBelongs: true,
          edit: this.openEditBossEmail,
        },
      ],
      rules: {
        email: [
          (v) => !!v || "入力してください。",
          (v) =>
            /.+@.+\..+/.test(v) || "メールアドレス形式で入力してください。",
        ],
        text: [(v) => !!v || "入力してください。"],
        select: [(v) => !!v || "選択してください。"],
      },
      accountLevelList: ACCOUNT_LEVEL_LIST,
      accountTypeList: ACCOUNT_TYPE_LIST,
      editParams: {
        type: "text",
        title: "",
        keyname: "",
        value: "",
        placeholder: "",
        appmetadata: false,
        rules: [],
      },
    };
  },
  computed: {
    metadata: function () {
      if (this.user.app_metadata.account_type === "C") {
        return this.app_metadata.filter(function (item) {
          return item.isCommon || item.isRegular;
        });
      } else if (
        ["P1", "P2", "P3", "P4"].includes(this.user.app_metadata.account_type)
      ) {
        if (this.isBelongsEmail()) {
          return this.app_metadata.filter(function (item) {
            return item.isCommon || item.isPolice || item.isBelongs;
          });
        } else {
          return this.app_metadata.filter(function (item) {
            return item.isCommon || item.isPolice;
          });
        }
      } else {
        return [];
      }
    },
  },
  filters: {
    formatdate(value) {
      return moment(value).format("YYYY/MM/DD HH:mm:ss");
    },
    accountLevel(value) {
      let item = ACCOUNT_LEVEL_LIST.find((v) => v.value === value);
      return item ? item.text : "";
    },
    accountType(value) {
      let item = ACCOUNT_TYPE_LIST.find((v) => v.value === value);
      return item ? item.text : "";
    },
  },
  methods: {
    // 編集
    openEditName() {
      this.editParams = {
        type: "text",
        title: "表示名",
        keyname: "name",
        value: this.user.name,
        placeholder: "東京-日本太郎-jc3",
        appmetadata: false,
        rules: this.rules.text,
        items: [],
      };
      this.$refs.edit.open();
    },
    openEditEmail() {
      this.editParams = {
        type: "text",
        title: "メールアドレス",
        keyname: "email",
        value: this.user.email,
        placeholder: "email@example.com",
        appmetadata: false,
        rules: this.rules.email,
        items: [],
      };
      this.$refs.edit.open();
    },
    openEditFullname() {
      this.editParams = {
        type: "text",
        title: "氏名（漢字）",
        keyname: "fullname",
        value: this.user.app_metadata.fullname,
        placeholder: "日本 太郎",
        appmetadata: true,
        rules: this.rules.text,
        items: [],
      };
      this.$refs.edit.open();
    },
    openEditFurigana() {
      this.editParams = {
        type: "text",
        title: "フリガナ",
        keyname: "furigana",
        value: this.user.app_metadata.furigana,
        placeholder: "ニッポン タロウ",
        appmetadata: true,
        rules: this.rules.text,
        items: [],
      };
      this.$refs.edit.open();
    },
    openEditAlphabetName() {
      this.editParams = {
        type: "text",
        title: "アルファベット表示",
        keyname: "alphabet_name",
        value: this.user.app_metadata.alphabet_name,
        placeholder: "NIPPON TARO",
        appmetadata: true,
        rules: this.rules.text,
        items: [],
      };
      this.$refs.edit.open();
    },
    openEditApplicantVerified() {
      this.editParams = {
        type: "bool",
        title: "情報管理規定等適用者確認",
        keyname: "applicant_verified",
        value: this.user.app_metadata.applicant_verified,
        placeholder: "",
        appmetadata: true,
        rules: this.rules.text,
        items: [],
      };
      this.$refs.edit.open();
    },
    openEditBelongs() {
      this.editParams = {
        type: "text",
        title: "所属",
        keyname: "belongs",
        value: this.user.app_metadata.belongs,
        placeholder: "",
        appmetadata: true,
        rules: this.rules.text,
        items: [],
      };
      this.$refs.edit.open();
    },
    openEditTelephoneNumber() {
      this.editParams = {
        type: "text",
        title: "連絡先電話番号",
        keyname: "telephone_number",
        value: this.user.app_metadata.telephone_number,
        placeholder: "",
        appmetadata: true,
        rules: this.rules.text,
        items: [],
      };
      this.$refs.edit.open();
    },
    openEditBossName() {
      this.editParams = {
        type: "text",
        title: "上司氏名",
        keyname: "boss_name",
        value: this.user.app_metadata.boss_name,
        placeholder: "",
        appmetadata: true,
        rules: this.rules.text,
        items: [],
      };
      this.$refs.edit.open();
    },
    openEditBossEmail() {
      this.editParams = {
        type: "text",
        title: "上司連絡先",
        keyname: "boss_email",
        value: this.user.app_metadata.boss_email,
        placeholder: "",
        appmetadata: true,
        rules: this.rules.email,
        items: [],
      };
      this.$refs.edit.open();
    },
    openEditAccountLevel() {
      //this.$refs.editAccountLevel.open()
      this.editParams = {
        type: "select",
        title: "会員種別",
        keyname: "account_level",
        value: this.user.app_metadata.account_level,
        placeholder: "",
        appmetadata: true,
        rules: this.rules.text,
        items: this.accountLevelList,
      };
      this.$refs.edit.open();
    },
    openEditAccountType() {
      //this.$refs.editAccountType.open()
      this.editParams = {
        type: "select",
        title: "所属種別",
        keyname: "account_type",
        value: this.user.app_metadata.account_type,
        placeholder: "",
        appmetadata: true,
        rules: this.rules.text,
        items: this.accountTypeList,
      };
      this.$refs.edit.open();
    },

    // パスワード変更
    openChangePassword() {
      this.$refs.changePassword.open();
    },

    // ユーザ削除
    openDelete() {
      this.$refs.deleteUser.open();
    },

    // 更新
    updateUser(user) {
      // 親コンポーネントのユーザを更新
      this.$emit("updateUser", user);
    },

    isBelongsEmail() {
      return this.user.email.endsWith("@jc3-learning.org") ? true : false;
    },
  },
};
</script>
