<template>
  <v-container>
    <v-btn text @click="toUsers">
      <v-icon left>mdi-arrow-left</v-icon>
      ユーザ一覧に戻る
    </v-btn>
    <v-row v-if="loading" justify="center" class="mt-16">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-row>
    <v-alert v-else-if="error" type="error">{{ error }}</v-alert>
    <v-container v-else>
      <!-- アイコン・表示名 -->
      <v-list-item>
        <v-list-item-icon>
          <v-avatar size="62">
            <img :src="user.picture"/>
          </v-avatar>
        </v-list-item-icon>
        <v-list-item-content>
          <div class="d-flex align-center">
            <div class="headline">{{ user.name }}</div>
          </div>
        </v-list-item-content>
      </v-list-item>

      <!-- タブ -->
      <v-tabs v-model="tab" color="black" style="border-bottom: 1px solid rgb(217, 217, 217);">
        <v-tab v-for="item in tabs" :key="item.tab">{{ item.text }}</v-tab>
      </v-tabs>

      <!-- ユーザ詳細 -->
      <user-details
        v-if="tab===0"
        :id="id"
        :user="user"
        @updateUser="updateUser"
      >
      </user-details>

      <!-- ロール -->
      <user-roles
        v-if="tab===1"
        :user="user"
      >
      </user-roles>

      <!-- 申請者 -->
      <user-applicants
        v-if="tab===2"
        :user="user"
      >
      </user-applicants>
    </v-container>

  </v-container>
</template>

<script>
import axios from "axios";
import UserDetails from '@/components/UserDetails'
import UserRoles from '@/components/UserRoles'
import UserApplicants from '@/components/UserApplicants'

export default {
  components: {
    UserDetails,
    UserRoles,
    UserApplicants
  },
  props: ['id'],
  data() {
    return {
      loading: false,
      error: '',
      user: {},

      //Tab
      tab: null,
      tabs: [
        { tab: 'detail', text: '詳細'},
        { tab: 'role', text: 'ロール'},
        { tab: 'applicant', text: '申請者'},
      ]
    }
  },
  created() {
    this.fetchUser()
  },
  methods: {
    async fetchUser() {
      this.loading = true
      this.error = ''
      const accessToken = await this.$auth.getTokenSilently()
      await axios
        .get(`/api/users/${this.id}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        .then(response => {
          if (response.data.error) {
            if (response.data.statusCode===404) {
              this.error = '存在しないユーザです。'
            } else [
              this.error = 'エラーが発生しました。'
            ]
          } else {
            this.user = response.data
          }
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    updateUser(user) {
      this.user = user
    },

    toUsers() {
      this.$router.push({name:'Users'})
    }
  }
}
</script>