<template>
  <v-dialog
    v-model="dialog"
    max-width="400px"
    @click:outside="dialog = false"
  >
    <v-card>
      <v-card-title>
        <span class="headline">ロール削除</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        "{{ username }}" のロール "{{ role.name }}" を削除していいですか？<br>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog=false">キャンセル</v-btn>
        <v-btn color="error" @click="send" :loading="loading">はい、削除します</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios"

export default {
  props: {
    userid: {
      type: String,
      required: true,
    },
    username: {
      type: String,
      required: true,
    },
    role: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
    }
  },
  methods: {
    open() {
      this.dialog = true
    },
    async send() {
      this.loading = true
      const accessToken = await this.$auth.getTokenSilently()
      let data = { roles: [this.role.id] }
      await axios.request({
        method: 'delete',
        url: `/api/users/${this.userid}/roles`,
        data: data, 
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      .then(() => {
        this.dialog = false
        this.$emit('success')
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false
      })
    },
  }
}
</script>