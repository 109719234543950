<template>
  <v-container>
    <div v-if="!applicants.length" class="text-center">no data</div>
    <div v-for="(apl, i) in applicants" :key="i">
      <div class="text-subtitle-1 font-weight-bold">{{ apl.id }}</div>
      <v-simple-table dense class="grey lighten-4">
        <template v-slot:default>
          <tbody>
            <tr>
              <td>申請日</td>
              <td>{{ apl.date }}</td>
            </tr>
            <tr>
              <td>申請代表者</td>
              <td>{{ apl.name }}</td>
            </tr>
            <tr>
              <td>申請代表者所属組織</td>
              <td>{{ apl.belongs }}</td>
            </tr>
            <tr>
              <td>申請代表者警電</td>
              <td>{{ apl.tel }}</td>
            </tr>
            <tr>
              <td>申請代表者メールアドレス</td>
              <td>{{ apl.email }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-divider v-if="i+1!==applicants.length" class="my-6"></v-divider>
    </div>
  </v-container>
</template>

<script>
import axios from "axios"

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      applicants: [],
    }
  },
  created() {
    if (this.user.app_metadata.applicant_ids && this.user.app_metadata.applicant_ids.length) {
      this.fetch()
    }
  },
  methods: {
    async fetch() {
      this.loading = true
      const accessToken = await this.$auth.getTokenSilently()
      await axios
        .get(`/api/applicants`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          params: {
            ids: this.user.app_metadata.applicant_ids,
          },
        })
        .then(response => {
          this.applicants = response.data
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
  
}
</script>